.our-values {
  display: flex;
  padding-top: 95px;
  padding-bottom: 84px;
  width: 100%;
  background-image: url("../img/triangles-left.png");
  background-position: left 30%;
  background-repeat: no-repeat;
  background-size: auto 30%;
}
.our-values img {
  object-fit: contain;
  margin-bottom: auto;
  margin-top: 36px;
  height: 35px;
  width: auto;
}

.our-values .details {
  display: flex;
  width: 63%;
  flex-wrap: wrap;
}

.our-values .details .item {
  padding-bottom: 45px;
  margin-right: auto;
  margin-top: -38px;
  width: 40%;
}

.our-values .details .item .item-title {
  color: var(--title);
  font-family: "Gotham", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 21px;
  margin-top: 35px;
  margin-bottom: 15px;
}

.our-values .details .item .text {
  font-family: Helvetica, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--text);
}
.our-values .action {
  display: flex;
  flex-direction: column;
  margin-left: 250px;
  margin-right: auto;
  width: 20%;
}
.our-values .action .title {
  margin-top: 33px;
  margin-bottom: 63px;
  font-size: 50px;
  line-height: 47px;
  font-family: "Gotham", serif;
  font-weight: bold;
}
.our-values .subtitle {
  font-size: 20px;
  font-family: "Gotham", serif;
  font-weight: 700;
  line-height: 17px;
  text-transform: uppercase;
}
.our-values .subtitle,
.our-values .title {
  color: var(--title);
}
.our-values .about.mobile {
  display: none;
}

@media only screen and (max-width: 500px) {
  .our-values {
    padding-top: 30px;
    flex-direction: column;
  }
  .our-values .action {
    margin-left: 60px;
    margin-top: 60px;
    margin-right: 60px;
    width: calc(100% - 190px);
  }
  .our-values .details {
    padding: 39px 60px;
    width: calc(100% - 60px - 60px);
  }
  .our-values .details .item {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .our-values .details .item img {
    width: 35px;
  }
  .our-values {
    background: none;
  }
  .our-values::before {
    content: "";
    background-image: url("../img/triangles-left.png");
    background-position: left 10%;
    background-repeat: no-repeat;
    background-size: auto 30%;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(-1);
  }
  .our-values .action .title {
    max-width: 200px;
  }
}